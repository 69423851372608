.wrapper {
    display: flex;
    margin: 0;
    align-items: center;

    .form {
        background: #ffffff;
        border-radius: 8px;
    }
}

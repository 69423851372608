@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: local('Roboto'), url(fonts/Roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto'), url(fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 300;
    src: local('Onest'), url(fonts/Onest/Onest-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 400;
    src: local('Onest'), url(fonts/Onest/Onest-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 500;
    src: local('Onest'), url(fonts/Onest/Onest-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 700;
    src: local('Onest'), url(fonts/Onest/Onest-Bold.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Resizer {
    background: #000;
    opacity: .12;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
}

.Resizer:hover {
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
}

.Resizer.horizontal:hover, .Resizer.horizontal.resizing {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover, .Resizer.vertical.resizing {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.DragLayer {
    z-index: 1;
    pointer-events: none;
}

.DragLayer.resizing {
    pointer-events: auto;
}

.DragLayer.horizontal {
    cursor: row-resize;
}

.DragLayer.vertical {
    cursor: col-resize;
}

.loading_wrapper {
    position: absolute;
    overflow: hidden;
    display: flex;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    div {
        display: inline-flex;
        margin: auto;

        div:first-child {
            margin-right: 20px;
        }
    }
}

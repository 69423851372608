*, *::before, *::after {
    box-sizing: initial;
}

html {
    --thumbBG: #00000059;
}
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
}

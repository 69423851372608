.caption {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.toolbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-transform: none;

    .title {
        flex: 1 1 100%;
    }
}

.tableRow {
    cursor: pointer;

    &:hover {
        background-color: #f7f7f7;
    }
}

.fitToContent {
    height: fit-content;
}

.autoHeight {
    height: auto;
}

.selectedRow {
    background-color: #f1f4fc;

    h6 {
        color: #6157ff;
    }
}

.groupHeader {
    text-transform: uppercase;

    td {
        border: 1px solid #d9deec;
    }


    td:first-child {
        div {
            cursor: pointer;
            margin-right: 8px;
            display: flex;
            height: 100%;
            align-self: center;
        }
    }

    &:hover {
        background-color: rgba(241, 244, 252, 0.4)
    }
}

.groupFooter {
    background-color: rgba(178, 182, 194, 0.07);
    text-align: right;
}

.inlineIcon {
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    height: 16px;
    width: 16px;
}

.openedIcon {
    background-image: url(../forms/images/tick.svg);
    transform: rotate(180deg);
}

.closedIcon {
    background-image: url(../forms/images/tick.svg);
}

.baseblue {
    color: #6157ff;
}

.wrapper {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    height: 95%;
}

#gridWrapper {
    height: 100%;
    display: grid !important;
}

.gridRefresh {
    z-index: 1;
    color: #aaaaaa !important;
    transition: color 100ms linear !important;
    padding: 9px 0 !important;;
    margin-right: auto;
    margin-left: inherit;
    min-height: inherit;

    &:hover {
        color: #6157ff !important;
    }
}

.fitContent {
    height: fit-content !important;
}

.panelButtonsBlock {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 2px;
    cursor: pointer;
    z-index: 1000;
}

.groupBox {
    padding: 8px;
}

.fullScreenButton {
    background-image: url('./images/fullscreen.svg');
    background-position: center center;
    background-size: cover;
    height: 18px;
    width: 18px;
}

.closeButton {
    background-image: url('./images/close.svg');
    background-position: center center;
    background-size: cover;
    height: 18px;
    width: 18px;
}

.alignDisable {
    div {
        align-content: inherit !important;
    }
}

.modalContainer {
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    background-color: white;
    width: 90vw;
    height: 90vh;
    top: 5vh;
    left: 5vw;
    overflow: auto;
    padding: 16px;
}

.pageButtonContainer {
    display: flex;
    border-bottom: 1px solid lightgray;
    position: relative;
}

.pageControlWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 100%;
    box-sizing: border-box;

    .tabContainer {
        /*position: absolute;*/
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fefefe;
        box-shadow: 0 2px 1px -1px #00000014 /*, 0px 1px 1px 0px #00000014, 0px 1px 3px 0px #00000014*/;
    }

    .tabPanel {
        flex-grow: 1;
        overflow: auto;
        /* margin-top: 30px;*/
    }
}

.pageButtonCommon {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    height: 32px;
    position: relative;
}
.pageButtonDivider {
    width: 16px;
}

.pageButtonActive {
    color: #000000;
}

.pageButtonActive .pageButtonUnderline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #f57069;
}

.pageButtonInactive {
    color: #6157ff;
    cursor: pointer;
}

.fastFilterSearchIcon {
    height: 22px;
    width: 22px;
    background-image: url('./images/search.svg');
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 8px;
    right: 22px;
}

.glossary {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.clearfix {
    clear: both;

    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.footer {
    order: 9999
}
